import Link from "next/link";

import { BarTitle } from "@/components/ui/lite/BarTitle";
import { NarrativeSummary } from "@/model/api";
import { getArticleUrl, getTickerUrl } from "@/util/article";

import MarketNewsMobile from "./MarketNewsMobile";

interface Props {
  list: NarrativeSummary[];
}

const MarketNews = ({ list }: Props) => (
  <>
    <MarketNewsMobile list={list} />
    <div
      className="hidden lg:block lg:mt-4 px-4"
      data-testid="home-market-news"
    >
      <div className="mb-6 pb-1 border-t-dolphin-silver border-b">
        <BarTitle title="Breaking News" className="text-xl lg:text-lg" />
      </div>
      <ul className="flex flex-col w-full justify-between space-y-4 ">
        {list.map((narrative: NarrativeSummary, index) => (
          <li
            key={`${narrative.Id}-${narrative.Date}-${index}`}
            className="scale-100 transition-all duration-150 border-t-dolphin-silver border-b last:border-b-0 rounded-none relative pb-3 w-full"
          >
            <div className="flex flex-col gap-1 h-full w-full">
              <Link
                href={getTickerUrl(narrative.Ticker)}
                className="truncate text-xs font-bold cursor-pointer group"
                passHref
              >
                <span className="text-fi-red group-hover:text-blue-light">
                  {narrative.Ticker}&nbsp; - &nbsp;
                </span>
                <span className="text-gray-600 font-semibold group-hover:text-fi-black group-hover:font-bold">
                  {narrative.CompanyName}
                </span>
              </Link>
              <Link
                href={getArticleUrl(narrative)}
                className="text-xs lg:text-sm font-semibold leading-tight text-fi-black cursor-pointer hover:text-fi-red"
                passHref
              >
                {narrative.Headline}
              </Link>
            </div>
          </li>
        ))}
      </ul>
    </div>
  </>
);

export default MarketNews;
