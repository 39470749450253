import Link from "next/link";

import { ArticleImage } from "@/components/ui/ArticleImage";
import { BarTitle } from "@/components/ui/lite/BarTitle";
import { NarrativeSummary } from "@/model/api";
import { getArticleUrl, getTickerUrl } from "@/util/article";

interface Props {
  list: NarrativeSummary[];
  title: string;
}

export const InsightsList: React.FC<Props> = ({ list, title }) => {
  return (
    <div>
      <div className="mr-6 mb-6">
        <BarTitle title={title} className="text-xl lg:text-lg" />
      </div>
      <ul className="flex flex-col w-full justify-between space-y-4">
        {list.map((narrative: NarrativeSummary, index: number) => {
          const articleLink = getArticleUrl(narrative);

          return (
            <li
              key={`${narrative.Id}-${narrative.Date}-${index}`}
              className="scale-100 transition-all duration-150 border-t-dolphin-silver border-b last:border-b-0 bg-white rounded-none relative pb-3 w-full"
            >
              <div className="w-full flex justify-between items-start space-x-2 lg:space-x-4">
                <Link
                  href={articleLink}
                  className="relative rounded-none w-[101px] h-20 lg:w-28 lg:h-[70px]"
                  passHref
                >
                  <ArticleImage ns={narrative} sizes="130px" />
                </Link>
                <div className="flex flex-col gap-1 h-full w-full pl-2 pr-1 lg:pl-0">
                  <Link
                    href={getTickerUrl(narrative.Ticker)}
                    className="text-fi-red font-bold cursor-pointer hover:text-blue-light pb-1 text-xs lg:pb-0 lg:text-sm"
                    passHref
                  >
                    {narrative.Ticker}
                  </Link>
                  <Link
                    href={articleLink}
                    className="font-semibold text-fi-black cursor-pointer hover:text-fi-red text-xs leading-normal lg:text-sm lg:leading-tight"
                    passHref
                  >
                    {narrative.Headline}
                  </Link>
                </div>
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};
