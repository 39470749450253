import { useCallback, useEffect, useState } from "react";

import HomeFooter from "@/components/home/HomeFooter";
import { Header } from "@/components/ui/lite/header";
import useIsMobile from "@/hooks/useIsMobileDevice";

interface Props {
  children: JSX.Element | JSX.Element[];
  themeBg?: string;
}

export const HomeLayoutV2 = ({ children, themeBg = "bg-white" }: Props) => {
  const isMobile = useIsMobile();
  const [scrolling, setScrolling] = useState(false);

  const handleScroll = useCallback(
    ({ top }: { top: number }) => {
      if (!scrolling && top > 0) {
        setScrolling(true);
      } else if (scrolling && top === 0) {
        setScrolling(false);
      }
    },
    [scrolling]
  );

  const onScroll = useCallback(() => {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    handleScroll({ top: scrollTop });
  }, [handleScroll]);

  useEffect(() => {
    if (isMobile) {
      window.addEventListener("scroll", onScroll);

      return () => {
        window.removeEventListener("scroll", onScroll);
      };
    }
  }, [isMobile, onScroll]);

  return (
    <div
      className={`${themeBg} absolute inset-0 font-roboto flex flex-col items-center overflow-y-auto`}
    >
      <Header />
      <div className="flex justify-between m-2 lg:my-8 lg:mx-auto px-0 flex-col lg:container lg:w-11/12 lg:flex-row">
        {children}
      </div>
      <HomeFooter />
    </div>
  );
};
