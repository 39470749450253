import Link from "next/link";
import React from "react";

import { ArticleImage } from "@/components/ui/ArticleImage";
import { NarrativeSummary } from "@/model/api";
import { getArticleUrl, getTickerUrl } from "@/util/article";

interface Props {
  list: NarrativeSummary[];
  topTeaser: string;
}

const MostImpactful = ({ list, topTeaser }: Props) => (
  <div className="flex flex-wrap relative w-full justify-between">
    {list.map((narrative, index) => {
      const articleLink = getArticleUrl(narrative);
      const isTop = index === 0;
      return (
        <div
          key={index}
          className={`group bg-white border-t-dolphin-silver pb-4 border-b lg:pb-0 lg:border-0 ${
            isTop
              ? "p-0 w-full"
              : `mt-4 flex items-start justify-between w-full lg:w-1/2 lg:flex-col ${
                  index % 2 === 0 ? "pl-0 lg:pl-4" : "pr-0 lg:pr-4"
                }`
          } ${index < list.length - 2 ? "" : "last:border-0"}`}
        >
          <div
            className={`flex-1 order-1 lg:-order-none w-full flex flex-col ${
              isTop ? "px-1" : "ml-4 lg:ml-0"
            }`}
          >
            <Link
              href={`${getTickerUrl(narrative.Ticker)}`}
              tabIndex={0}
              className={`flex group items-center tracking-wide space-x-2 ease-in-out outline-none focus:outline-none rounded-md focus:z-10 focus:ring-1 focus:ring-blue-light/100 cursor-pointer ${
                isTop ? "py-1" : ""
              }`}
              passHref
            >
              <p className="text-fi-red text-xs font-bold transition-all duration-150 group-hover:text-blue-light group-hover:drop-shadow-sm">
                {narrative.Ticker}
              </p>
              <p>-</p>
              <p className="text-gray-500 truncate text-xs transition-all duration-150 font-semibold group-hover:font-bold group-hover:text-fi-black group-hover:drop-shadow-sm">
                {narrative.CompanyName}
              </p>
            </Link>
            <Link
              href={articleLink}
              className={isTop ? "-order-1" : ""}
              data-testid={`most-impactful-article-${index}`}
              passHref
            >
              <p
                className={`${
                  isTop
                    ? "tracking-tight leading-7 text-2xl lg:text-4xl"
                    : "leading-5 lg:leading-[18px] text-xs lg:text-lg"
                } lg:hover:text-fi-red font-bold text-fi-black`}
              >
                {narrative.Headline}
              </p>
            </Link>
            {isTop && (
              <Link href={articleLink} passHref>
                <p className="text-sm lg:text-base text-dolphin-gray text my-0.5 hyphens-auto leading-4 lg:leading-5 text-justify">
                  {topTeaser}
                </p>
              </Link>
            )}
          </div>

          <div
            className={`bg-gradient-to-b from-zinc-400 via-zinc-500 to-black relative rounded-md flex-grow-0 my-0 lg:rounded lg:flex-grow lg:my-4 ${
              isTop
                ? "max-h-48 min-h-48 h-48 w-full lg:max-h-96 lg:h-96 lg:min-h-96"
                : "max-h-20 min-h-20 h-20 w-20 lg:max-h-48 lg:min-h-48 lg:h-48 lg:w-full"
            }`}
          >
            <Link
              href={articleLink}
              className="absolute inset-0 mix-blend-overlay"
              id={narrative.Id}
              passHref
            >
              <ArticleImage
                ns={narrative}
                priority={isTop}
                sizes={
                  isTop
                    ? "(max-width: 1024px) 90vw, 50vw"
                    : "(max-width: 1024px) 130px, 25vw"
                }
              />
            </Link>
          </div>
        </div>
      );
    })}
  </div>
);

MostImpactful.displayName = "MostImpactful";

export default MostImpactful;
