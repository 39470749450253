import axios, { AxiosError } from "axios";
import dynamic from "next/dynamic";
import { GetServerSideProps, InferGetServerSidePropsType } from "next/types";
import React from "react";
import { shallowEqual } from "react-redux";

import { EdgeAPI } from "@/api/Edge";
import { InsightsList } from "@/components/home/InsightsList";
import MarketNews from "@/components/home/marketNews/MarketNews";
import MostImpactful from "@/components/home/MostImpactful";
import { useAppSelector } from "@/hooks";
import { useGetFetch } from "@/hooks/useGetFetch";
import AxiosInit from "@/layouts/AxiosInit";
import { HomeLayoutV2 } from "@/layouts/HomeLayoutV2";
import {
  NarrativeEnrichment,
  NarrativeSummary,
  TopNarratives,
} from "@/model/api";
import { FolikoPage } from "@/model/page";
import { BaseUrl } from "@/util/app";
import { getArticleBaseUrl } from "@/util/article";

export interface PageProps {
  articles: TopNarratives;
  error?: string;
  topTeaser: string;
}

export const getServerSideProps: GetServerSideProps<PageProps> = async () => {
  await AxiosInit();
  let error: AxiosError | undefined;
  const articles = await axios
    .get(EdgeAPI.TopNarratives)
    .then((response) => response.data as TopNarratives)
    .catch((err: AxiosError) => {
      error = err;
      return {} as TopNarratives;
    });
  const topTeaser = await axios
    .get(
      `${EdgeAPI.GetNarrativeEnrichments}/${getArticleBaseUrl(
        articles.TopPrimary[0]
      )}`
    )
    .then((response) => response.data as NarrativeEnrichment)
    .then((enrichment) => enrichment.Teaser)
    .catch((err: AxiosError) => {
      error = err;
      return "";
    });

  if (error) {
    return {
      props: {
        articles: {} as TopNarratives,
        error: error.response?.data ?? error.message,
        topTeaser: "",
      },
    };
  }

  return {
    props: {
      articles,
      topTeaser,
    },
  };
};

const HomeV2: FolikoPage<PageProps> = ({
  articles,
  topTeaser,
}: InferGetServerSidePropsType<typeof getServerSideProps>) => {
  const siteState = useAppSelector((state) => state.site, shallowEqual);

  const { data: bookmarkNarrativesData } = useGetFetch<NarrativeSummary[]>(
    EdgeAPI.BookmarkNarratives,
    [],
    siteState.axiosAuthReady
  );

  return (
    <HomeLayoutV2>
      <div className="min-h-screen w-full flex flex-col lg:flex-row">
        <div className="w-full bg-white rounded order-1 lg:w-1/5 lg:bg-gray-100 lg:-order-none">
          <MarketNews list={articles.TopSecondary.slice(0, 13)} />
        </div>
        <div className="flex-1 border-t-dolphin-silver px-0 order-0 lg:px-8 lg:border-r lg:-order-none">
          <MostImpactful list={articles.TopPrimary} topTeaser={topTeaser} />
        </div>
        <div className="border-t-dolphin-silver w-full order-2 px-0 lg:min-w-[300px] lg:w-1/5 lg:-order-none lg:pl-8">
          <div className="flex flex-col gap-6 items-start justify-center">
            {bookmarkNarrativesData && bookmarkNarrativesData.length > 0 && (
              <InsightsList
                list={bookmarkNarrativesData}
                title="Top Picks For You"
              />
            )}
            <InsightsList list={articles.TopWeekly} title="Week In Review" />
            <InsightsList list={articles.Trending} title="Most Read" />
          </div>
        </div>
      </div>
    </HomeLayoutV2>
  );
};

HomeV2.getMetadata = () => ({
  pageDescription: () => "Investor news for a new age.",
  pageThumbnail: () => `${BaseUrl}/img/thumbnails/foliko-thumb-teaser.png`,
  pageTitle: () => "Foliko Insights - AI Powered Investor News",
  pageUrl: () => BaseUrl,
});

export default HomeV2;
